import { hideHeader, showHeader  } from './tools.js';

/* --------------------------------------------------------------------
Hide header bar on scroll.
Reveal after period of inactivity.
-------------------------------------------------------------------- */
let hideHeaderTriggerPoint = 0;
var isScrolling, isWaiting, lastScrollPos;

window.addEventListener('scroll', function(){
    // Clear our timeout throughout the scroll
    window.clearTimeout( isScrolling );
    window.clearTimeout( isWaiting )


    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function() {
        // Run the callback

        // Wait 20 seconds, if still in active, show header again.
        isWaiting = setTimeout(function() {
            showHeader();
        }, 10000);

    }, 66);

    // Header Show/Hide
    if(lastScrollPos > getYPosition()){
        console.log('showHeader');
        showHeader();
    }
    else if(getYPosition() > hideHeaderTriggerPoint){
        console.log('hideHeader');
        hideHeader();
    }
    else{
        console.log('showHeader');
        showHeader();
    }

    lastScrollPos = getYPosition();
});


function getYPosition(){
    var top  = window.pageYOffset || document.documentElement.scrollTop
    return top;
}